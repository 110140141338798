<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-row">
				<pui-field-set :title="$t('pmschema.title')">
					<v-row no-gutters>
						<v-col xs12 md6>
							<pui-text-field :label="$t('pmschema.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-col>
						<v-col xs12 md6>
							<pui-text-field :label="$t('pmschema.acronym')" v-model="model.acronym" required toplabel maxlength="50"></pui-text-field>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col xs12 md6>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmschema.organization')"
								:placeholder="this.$t('pmschema.phorganization')"
								toplabel
								clearable
								:disabled="formDisabled"
								required
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmspatialtables.organization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-col>
						<v-col xs12 md6>
							<pui-select
								attach="pmdatamodelid"
								:label="this.$t('pmschema.datamodel')"
								:placeholder="this.$t('pmschema.phdatamodel')"
								toplabel
								clearable
								:disabled="!model.pmorganizationid"
								required
								v-model="model"
								modelName="vluppmdatamodel"
								:modelFormMapping="{ pmdatamodelid: 'pmdatamodelid' }"
								:itemsToSelect="datamodelItemsToSelect"
								:fixedFilter="datamodelFilter"
								itemValue="pmdatamodelid"
								itemText="name"
							></pui-select>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col xs12>
							<pui-text-area v-model="model.description" :label="$t('pmschema.description')" maxlength="250" toplabel></pui-text-area>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col xs12>
							<pui-field-set :title="$t('pmschema.jsonschema')">
								<v-jsoneditor
									v-model="jsonschema"
									:options="options"
									:plus="false"
									height="350px"
									@error="onErrorQuery"
								></v-jsoneditor>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-layout wrap>
						<v-col xs12 md2>
							<pui-checkbox
								:label="$t('pmschema.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								class="mt-2"
							></pui-checkbox>
						</v-col>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="validateAndSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { isSuperAdmin } from '@/api/common';

import VJsoneditor from 'v-jsoneditor';

export default {
	name: 'PmSchemaForm',
	mixins: [PuiFormMethodsMixin],
	components: {
		VJsoneditor
	},
	data() {
		return {
			modelName: 'pmschema',
			model: 'pmschema',
			isSuperAdmin: false,
			options: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
				/* onChangeText(json) {
					console.log('onChange query', json);
				} */
			},
			validJson: false,
			jsonschema: null,
			datamodelFilter: {}
		};
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		datamodelItemsToSelect() {
			return [{ pmdatamodelid: this.model.pmdatamodelid }];
		}
	},
	watch: {
		jsonschema: {
			handler(newValue, oldValue) {
				console.log('jsonschema', oldValue, newValue);
				if (newValue) {
					this.validJson = true;
					this.model.jsonschema = JSON.stringify(this.jsonschema);
				}
			}
		},
		'model.pmorganizationid': {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				if (!newValue) this.model.pmdatamodelid = null;
				else {
					this.datamodelFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: newValue }]
					};
				}
			}
		},
		'model.pmschemaid': {
			handler(newValue, oldValue) {
				console.log('pmschemaid', oldValue, newValue);

				if (newValue) this.jsonschema = JSON.parse(this.model.jsonschema);
				else {
					this.datamodelFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: newValue }]
					};
				}
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
			if (!isSuperAdmin(this.session.profiles[0])) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		console.log('mounted', this.model.jsonschema);
	},
	updated() {
		console.log('updated', this.model.jsonschema, this.jsonschema);
		if (this.model) {
			if (!this.isSuperAdmin && !this.model.pmorganizationid) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {
		validateAndSave() {
			if (this.validJson) this.model.jsonschema = JSON.stringify(this.jsonschema);
			else this.model.jsonschema = null;
			console.log('saving', this.model.jsonschema, this.validJson);

			this.save();
		},
		onErrorQuery() {
			console.log('error query');
			this.validJson = false;
		}
	}
};
</script>
